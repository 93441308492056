<!-- 个人关注相关列表 -->
<template>
  <div class="detailsTheListOf">
    <!-- 关注者 -->
    <div class="detailsList" id="focusList" v-loading="focusLoading">
      <!-- 追随我的数据--关注者 -->
      <!-- 标题 -->
      <!-- 关注者  vue_label_CCChat_followers -->
      <div class="detailsList_title">
        <div class="titleImg">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-teampeople"></use>
          </svg>
        </div>
        <div>
          {{ $t("vue_label_CCChat_followers") }}({{ followersMyCount }})
        </div>
      </div>
      <!-- 内容 -->
      <div v-if="followersMyData.length > 0" class="detailsList_content">
        <div
          class="content_son"
          v-for="(item, index) in followersMyData"
          :key="index"
        >
          <div>
            <p>
              <img
                :src="`${imgUrlCookie}/chatterzone.action?m=showPhoto&userid=${item._id}&binding=${tokenCookie}`"
                style="width: 26px; height: 26px; border-radius: 50%"
                alt
              />
            </p>
            <p @click="toPersonDetails(item)">{{ item.name }}</p>
          </div>
          <!-- 电话 label.mr.title.phone -->
          <div>
            <p>{{ $t("label.mr.title.phone") }}:</p>
            <p>{{ item.mobile }}</p>
          </div>
          <!--职位  label_emailtocloudcc_position -->
          <div>
            <p>{{ $t("label_emailtocloudcc_position") }}:</p>
            <p>{{ item.title }}</p>
          </div>
          <!-- 部门  label.department-->
          <div>
            <p>{{ $t("label.department") }}:</p>
            <p>{{ item.department }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 正在关注 -->
    <div class="detailsList" id="focusListItemone" v-loading="focusLoading">
      <!-- 标题 -->
      <div class="detailsList_title">
        <div class="titleImg">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-teampeople"></use>
          </svg>
        </div>
        <!-- 正在关注 label.chatter.following -->
        <div>{{ $t("label.chatter.following") }}({{ myfollowerCount }})</div>
      </div>
      <!-- 内容 -->
      <div class="detailsList_content" v-if="myfollowersData.length > 0">
        <div
          class="content_son"
          v-for="(item, index) in myfollowersData"
          :key="index"
        >
          <div>
            <p>
              <img
                :src="`${imgUrlCookie}/chatterzone.action?m=showPhoto&userid=${item._id}&binding=${tokenCookie}`"
                style="width: 26px; height: 26px; border-radius: 50%"
                alt
              />
            </p>
            <p @click="toPersonDetails(item)">{{ item.name }}</p>
          </div>
          <div>
            <p>{{ $t("label.mr.title.phone") }}:</p>
            <p>{{ item.mobile }}</p>
          </div>
          <div>
            <p>{{ $t("label_emailtocloudcc_position") }}:</p>
            <p>{{ item.title }}</p>
          </div>
          <div>
            <p>{{ $t("label.department") }}:</p>
            <p>{{ item.department }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 小组 -->
    <div class="detailsList" id="focusListItemtwo" v-loading="focusLoading">
      <!-- 标题 -->
      <div class="detailsList_title">
        <div class="titleImg">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-team"></use>
          </svg>
        </div>
        <!-- 小组 label.chat.micropost.a.group -->
        <div>{{ $t("label.chat.micropost.a.group") }}({{ myGroupCount }})</div>
      </div>
      <!-- 内容 -->
      <div class="detailsList_content" v-if="myGroupData.length > 0">
        <div
          class="content_son"
          v-for="(item, index) in myGroupData"
          :key="index"
        >
          <div>
            <p>
              <img
                :src="`${imgUrlCookie}/chatterGroup.action?m=showGroupImage&id=${item._id}&binding=${tokenCookie}`"
                style="width: 26px; height: 26px"
                alt
              />
            </p>
            <p @click="toGroupDetails(item)">{{ item.name }}</p>
          </div>
          <!-- 所有人 label.referperson -->
          <div>
            <p>{{ $t("label.referperson") }}:</p>
            <p>{{ item.ownerName }}</p>
          </div>
          <!-- 备注  label.description -->
          <div>
            <p>{{ $t("label.description") }}:</p>
            <p>{{ item.description }}</p>
          </div>
          <!-- 其他 label.ems.other -->
          <div>
            <p>{{ $t("label.ems.other") }}:</p>
            <!-- 这个是随便取的数据，这个代表的是小组的类型是公用还是私用 -->
            <p>{{ item.groupTypeValue }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
export default {
  name: "detailsTheListOf",
  data() {
    return {
      tokenCookie: "", //token
      imgUrlCookie: "", //域名
      focusLoading: true,
    };
  },
  props: {
    //追随我的数据--关注者
    followersMyData: {
      type: Array,
      default: () => [],
    },
    followersMyCount: {
      type: Number,
      default: 1,
    },
    //我追随的数据--正在关注
    myfollowersData: {
      type: Array,
      default: () => [],
    },
    myfollowerCount: {
      type: Number,
      default: 1,
    },
    // 我的组（包含我创建的组和我加入的组）--小组数量
    myGroupCount: {
      type: Number,
      default: 1,
    },
    // 我的组（包含我创建的组和我加入的组
    myGroupData: {
      type: Array,
      default: () => [],
    },
  },
  beforeDestroy() {
    this.$Bus.$off("focusListLoading", this.focusListLoading)
  },
  mounted() {
    //token
    this.tokenCookie = this.$CCDK.CCToken.getToken();
    //域名
    this.imgUrlCookie = VueCookies.get("domainName");
    this.$Bus.$on("focusListLoading", this.focusListLoading);
  },
  methods: {
    focusListLoading(val) {
      this.focusLoading = val;
    },
    // 个人详情页跳转
    toPersonDetails(e) {
      this.$router.push({
        path: `/commonObjects/detail/${e.userid}/DETAIL`,
      });
    },
    // 小组详情页跳转
    toGroupDetails(e) {
      this.$router.push({
        path: `/colleagueCircle/dynamic`,
        query: {
          id: e._id,
          category: "ChatterGroup",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.detailsTheListOf {
  margin-top: 30px;
  width: 99%;
  padding-bottom: 12px;
  .detailsList {
    border-radius: 3px 3px 0px 0px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #dddbda;
    margin-top: 10px;
    .detailsList_title {
      display: flex;
      padding: 16px 10px;
      align-items: center;
      font-size: 12px;
      color: #080707;
      .titleImg {
        .icon {
          width: 20px;
          height: 20px;
          border-radius: 3px;
        }
      }
      div:nth-of-type(2) {
        margin-left: 0.8%;
      }
    }
    .detailsList_content {
      display: flex;
      justify-content: space-between;
      background: white;
      padding: 0px 15px 0px 15px;
      flex-wrap: wrap;
      .content_son {
        &:first-of-type {
          margin-top: 12px;
        }
        &:nth-of-type(2) {
          margin-top: 12px;
        }
      }
      .content_son {
        padding: 12px 0px 12px 20px;
        width: 47%;
        background: #f3f2f2;
        margin-bottom: 12px;
        div {
          display: flex;
          flex-wrap: wrap;
          padding: 4px 0;
          &:first-of-type {
            p:nth-of-type(2) {
              margin-left: 3%;
              font-size: 13px;
              cursor: pointer;
            }
          }
          &:nth-of-type(2) {
            p:nth-of-type(1) {
              // border: solid 1px;
              min-width: 150px;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // white-space: nowrap;
            }
            p:nth-of-type(2) {
              // margin-left: 15.5%;
              margin-left: 1%;
            }
          }
          &:nth-of-type(3) {
            p:nth-of-type(1) {
              // border: solid 1px;
              min-width: 150px;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // white-space: nowrap;
            }
            p:nth-of-type(2) {
              // margin-left: 15.5%;
              margin-left: 1%;
            }
          }
          &:nth-of-type(4) {
            p:nth-of-type(1) {
              // border: solid 1px;
              min-width: 150px;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // white-space: nowrap;
            }
            p:nth-of-type(2) {
              // margin-left: 15.5%;
              margin-left: 1%;
            }
          }
          p:nth-of-type(1) {
            font-size: 12px;
            color: #666666;
          }
          p:last-of-type {
            max-width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            color: #080707;
            font-weight: 500;
            margin-left: 18%;
          }
          &:first-of-type {
            img {
              width: 20px;
              height: 20px;
              vertical-align: 0;
            }
            p {
              font-size: 14px;
              color: #006dcc;
            }
          }
        }
      }
    }
  }
}
</style>
