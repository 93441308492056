<template>
  <div class="detailsCircle">
    <div class="detailsCircleContent">
      <!-- 个人头部基本信息 -->
      <detailsPersonal></detailsPersonal>
      <div class="detailsDetails">
        <div class="detailsDetails_left">
          <!-- 个人关注相关列表 -->
          <detailsTheListOf></detailsTheListOf>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DetailsPersonal from "@/views/colleagueCircle/components/details/DetailsPersonal";
import DetailsTheListOf from "@/views/colleagueCircle/components/details/DetailsTheListOf";
export default {
  components: {
    DetailsPersonal,
    DetailsTheListOf,
  }
};
</script>

<style lang="scss" scoped>
.detailsCircle {
  padding-top: 38px;
  .detailsCircleContent {
    padding: 0 10px;
    // 个人头部基本信息
    .detailsPersonal {
      background: white;
      width: 100%;
    }
    .detailsDetails {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      // 个人详细信息和个人关注相关列表
      .detailsDetails_left {
        width: calc(100% - 439px);
        margin-right: 10px;
        .el-tabs,
        .el-tabs--top {
          background: white;
          box-shadow: 0px 4px 10px #ccc;
          // 个人详细信息
          .detalisInformations {
          }
        }

        // 个人关注相关列表
        .detailsTheListOf {
        }
      }
    }
  }
}
</style>